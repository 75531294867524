
import barba from '@barba/core';
import anime from 'animejs';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
       const heroContent = document.querySelector("#l-hero__content")
       const heroCatch = document.querySelectorAll(".l-hero__catch--en")
        if(heroCatch){
            let regexp = /[\u{3000}-\u{301C}\u{3041}-\u{3093}\u{309B}-\u{309E}]/mu;
            let heroPrimary = document.querySelectorAll('.l-hero__catch--en')
            if(heroPrimary){
                heroPrimary.forEach( (h,i) => {
                    let sc_str = h.textContent.split("");
                    let str = "";
                    sc_str.map( s => {
                        str += "<span class='hero__str'>" + s  + "</span>";
                    })
                        
                    h.innerHTML = str;
                })
            }
            let heroSecondary = document.querySelectorAll('.l-hero__catch--ja')
            if(heroSecondary){
                heroSecondary.forEach( (h,i) => {
                    let sc_str = h.textContent.split("");
                    let str = "";
                    sc_str.map( s => {
                        str += "<span class='hero__str'>" + s  + "</span>";
                    })
                        
                    h.innerHTML = str;
                })
            }

            setTimeout(function(){
                let str_animation =  anime.timeline();
                str_animation
                .add({
                    targets : '#l-hero--image',
                    scale: [1.1,1],
                    translateX: ["10%",0],
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:2000
                })
                .add({
                    targets : '.l-hero__catch--en .hero__str',
                    easing: 'easeOutBack',
                    duration : 2000,
					opacity: [0,1],
					
					delay:function(el,i){
                        return anime.random(0,500);
					}
                },"-=1300")
                .add({
                    targets : '.l-hero__catch--ja .hero__str',
                    easing: 'easeOutBack',
                    duration : 2000,
					opacity: [0,1],
					
					delay:function(el,i){
                        return anime.random(0,500);
					}
                },"-=2000")
            },600)
       }
    }
}