

import barba from '@barba/core';
import ScrollTrigger from '@terwanerik/scrolltrigger'
import anime from 'animejs';


export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.trigger = new ScrollTrigger()
            this.initailTrigger()
        });
        barba.hooks.leave((data) => {
            this.trigger.kill()
        });
        barba.hooks.after((data) => {
            this.trigger = new ScrollTrigger()
            this.initailTrigger()
        });

    }
    initailTrigger(){
        this.trigger.add('[data-trigger]', {
            once: true,
            offset: {
               
                viewport: {
                    x: 0,
                    y: (trigger, frame, direction) => {
                        return trigger.visible ? 0 : 0.3
                    }
                }
            },
            toggle: {
                // class: {
                //     in: 'visible',
                //     out: ['invisible', 'extraClassToToggleWhenHidden']
                // },
                // callback: {
                //     in: null,
                //     visible: null,
                //     out: (trigger) => {
                //         return new Promise((resolve, reject) => {
                //             setTimeout(resolve, 10)
                //         })
                //     }
                // }
            }
        })

        const strAnimations = document.querySelectorAll('[data-trigger="translateY"]')
        if(strAnimations.length > 0 ){
            strAnimations.forEach((el) => {
                let _str = el.textContent.split("");
                let str = "";
                _str.map( s => {
                    str += "<span class='str__animation'>" + s  + "</span>";
                })
                    
                el.innerHTML = str;
            })
        }

        this.trigger.add('[data-trigger="concept"]',
            { 
                once: true,
                offset: {
                    viewport: {
                        y: (trigger, frame, direction) => {
                            return trigger.visible ? 0 : .1
                        }
                    }
                },
                toggle: {
                    callback: {
                        in: (trigger) => {
                            
                            const timeline = anime.timeline()
                            timeline
                            .add({
                                targets: '#p-index__concept--text01 .p-index__concept--text-cover',
                                translateX:[0,'100%'],
                                easing: 'easeOutQuart',
                                begin: ()=>{
                                    anime({
                                        targets: '#p-index__concept--text01',
                                        opacity: [0,1],
                                        easing: 'easeOutQuart',
                                        duration: 2800,
                                    })
                                },
                                duration: 3400,
                            })
                            .add({
                                targets: '#p-index__concept--text02 .p-index__concept--text-cover',
                                translateX:[0,'100%'],
                                easing: 'easeOutQuart',
                                begin: ()=>{
                                    anime({
                                        targets: '#p-index__concept--text02',
                                        opacity: [0,1],
                                        easing: 'easeOutQuart',
                                        duration: 2800,
                                    })
                                },
                                duration: 2600,
                            },'-=800')
                            .add({
                                targets: '#p-index__concept--text03 .p-index__concept--text-cover',
                                translateX:[0,'100%'],
                                easing: 'easeOutQuart',
                                duration: 2600,
                                begin: ()=>{
                                    anime({
                                        targets: '#p-index__concept--text03',
                                        opacity: [0,1],
                                        easing: 'easeOutQuart',
                                        duration: 2600,
                                    })
                                },
                            },'-=1200')
                            .add({
                                targets: '#p-index__concept--text04 .p-index__concept--text-cover',
                                translateX:[0,'100%'],
                                easing: 'easeOutQuart',
                                begin: ()=>{
                                    anime({
                                        targets: '#p-index__concept--text04',
                                        opacity: [0,1],
                                        easing: 'easeOutQuart',
                                        duration: 2600,
                                    })
                                },
                                duration: 2600,
                            },'-=1200')
                            .add({
                                targets: '#p-index__concept--text05 .p-index__concept--text-cover',
                                translateX:[0,'100%'],
                                easing: 'easeOutQuart',
                                begin: ()=>{
                                    anime({
                                        targets: '#p-index__concept--text05',
                                        opacity: [0,1],
                                        easing: 'easeOutQuart',
                                        duration: 2600,
                                    })
                                },
                                duration: 2600,
                            },'-=1200')
                            .add({
                                targets: '#p-index__concept--text06 .p-index__concept--text-cover',
                                translateX:[0,'100%'],
                                easing: 'easeOutQuart',
                                begin: ()=>{
                                    anime({
                                        targets: '#p-index__concept--text06',
                                        opacity: [0,1],
                                        easing: 'easeOutQuart',
                                        duration: 2800,
                                    })
                                },
                                duration: 3400,
                            },)
                        }
                    }
                }
            },
        )
    }
}