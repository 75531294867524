
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.casesSlider()
            this.heroSlider()
        })
        barba.hooks.after((data) => {
            this.casesSlider()
            this.heroSlider()
        });
        
    }
    heroSlider(){
        const heroSlider =  new Swiper ('.l-hero__slider',{
            effect :'fade',
            autoplay: true,
            autoplaySpeed:100,
            speed: 16000,
        })
        const heroCatch = document.querySelector('#l-hero__catch') 
        if(heroCatch){
            heroSlider.on('slideChange', () => {
                if( heroSlider.activeIndex == 0 ){
                    heroCatch.classList.remove('-night')
                }else{
                    heroCatch.classList.add('-night')
                }
            })
        }
    }

    casesSlider(){
        new Swiper ('.p-index__cases__slider',{
            loop: true,
            effect :'slide',
            speed:1000,
            allowTouchMove: false,
            navigation:  {
                nextEl: '.p-index__cases__container .swiper-button-next',
                prevEl: '.p-index__cases__container .swiper-button-prev',
            }, 
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        })
    }
}