
import barba from '@barba/core';
import ImageCompare from "image-compare-viewer";

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.ImageCompare()
        })
        barba.hooks.after((data) => {
            this.ImageCompare()
        });
        
    }

    ImageCompare(){
        let viewers = document.querySelectorAll(".image-compare");
  
        viewers.forEach((element) => {
        
          const options = {

              // UI Theme Defaults
            
              controlColor: "#FFFFFF",
              controlShadow: true,
              addCircle: false,
              addCircleBlur: true,
            
              // Label Defaults
            
              // showLabels: true,
              // labelOptions: {
              //   before: beforeLabel,
              //   after: '',
              //   onHover: false,
              // //   before: beforeLabel
              // },
            
              // Smoothing
            
              smoothing: true,
              smoothingAmount: 100,
            
              // Other options
            
              hoverStart: false,
              verticalMode: false,
              startingPoint: 90,
              fluidMode: false
          };

        // const beforeLabel = element.nextElementSibling.innerHTML;
        // const options = {
        //     showLabels: true,
        //     labelOptions: {
        //         before: beforeLabel,
        //     }
        // };
            
          // Add your options object as the second argument
          const viewer = new ImageCompare(element, options).mount();

          
          const label = element.nextElementSibling
          const beforeWrapper = element.querySelector('.icv__wrapper')
          beforeWrapper.append(label)
        });

    }
}